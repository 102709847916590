<template>
	<div class="main" :class="show ? 'h5' : ''">
		<div class="htop">
		<mtop />
		</div>
		<div class="banner">
				<img
            src="../assets/ximg/banner-fxsc@2x.png"
            alt="fxsc"
            class="simg"
          />
		</div>
        <div class="content">
            <div class="box">
                <div class="btop">
				<div class="title">什么是微信商城</div>
				<div class="tips">基于微信开发的私域流量商城</div>
				</div>
                <div class="cont">
                    <div class="vcard">
                        <div class="timg"><img src="../assets/ximg/wsc-1@2x.png"/></div>
                        <div class="bottom">
                            <div class="title">微商城建设开发</div>
                            <div class="txt">基于手机微信访问、展示手机商城商品，您可在手机端进行商品购物。</div>
                        </div> 
                    </div>

                    <div class="vcard">
                        <div class="timg"><img src="../assets/ximg/wsc-2@2x.png"/></div>
                        <div class="bottom">
                            <div class="title">微信商城</div>
                            <div class="txt">以微信为入口，利用微信数据接口开发，集营销、购物、支付、会员管理为一体的微信商城。</div>
                        </div> 
                    </div>

                    <div class="vcard">
                        <div class="timg"><img src="../assets/ximg/wsc-3@2x.png"/></div>
                        <div class="bottom">
                            <div class="title">增值服务</div>
                            <div class="txt">在微信商城购物基础上的增值服务，功能扩展、界面美化、网络营销等服务。</div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content bg">
            <div class="box">
                <div class="cont">
                    <div class="left">
                        <img src="../assets/ximg/gngk@2x.png"/>
                    </div>                    
                <div class="bxcard">
                    <div class="title">功能概况</div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/gngk-1@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">1500</span>
                            <span>专业功能</span>
                            </div>
                            <div class="xtips">商城拥有1500项全网最丰富的专业功能，让您轻松应对各类商城业务</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/gngk-2@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">27</span>
                            <span>功能概况</span>
                            </div>
                            <div class="xtips">商城已开发27款业务插件，可从营销、管理等多个方面，促进您商城的发展。</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/gngk-3@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">56</span>
                            <span>基础应用</span>
                            </div>
                            <div class="xtips">商城包含56款基础应用，助您迅速搭建商城，即刻开启电商之旅。</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/gngk-4@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">14</span>
                            <span>营销设置</span>
                            </div>
                            <div class="xtips">14种营销设置，便于商城随时开展营销活动，吸引会员购买消费。</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/gngk-5@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">8</span>
                            <span>大商城板块</span>
                            </div>
                            <div class="xtips">首页、商品、会员、订单、营销、财务、数据、应用。</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/gngk-6@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">4</span>
                            <span>大支付方式</span>
                            </div>
                            <div class="xtips">微信支付、支付宝支付、余额支付、货到付款。</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cont">
                                    
                <div class="bxcard">
                    <div class="title">特色功能</div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/tsgn-1@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">11</span>
                            <span>款营销类功能</span>
                            </div>
                            <div class="xtips">分销、互动直播、拼团、活动海报、兑换中心、砍价活动、游戏系统、营销宝、
任务中心、整点秒杀、超级海报</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/tsgn-2@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">9</span>
                            <span>款工具类功能</span>
                            </div>
                            <div class="xtips">店铺装修、微信卡券、积分签到、会员群发、手机端商家管理中心、优惠券、积分商城、商品助手</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/tsgn-3@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">9</span>
                            <span>款业务类功能</span>
                            </div>
                            <div class="xtips">多商户、小程序、应用授权管理、收银台、区域代理、020核销、全民股东、进销存-网店管家</div>
                        </div>
                    </div>
                    <div class="line">
                        <div class="ico"><img src="../assets/ximg/tsgn-4@2x.png"/></div>
                        <div class="xbox">
                            <div class="stxt">
                            <span class="bb">10</span>
                            <span>款辅助类功能</span>
                            </div>
                            <div class="xtips">快速购买、短信接口、小票打印机、自定义表单、社区、分权系统、快递助手、全网通、文章营销、帮助中心</div>
                        </div>
                    </div>
              
              
                </div>
                <div class="right">
                        <img src="../assets/ximg/tsgn@2x.png"/>
                    </div>  
            </div>

            </div>
        </div>

		<div class="content">
            <div class="ctitle">主要功能</div>
            <div class="ctop">
                <div class="tab">
                    <div class="item" :class="type==1?'act':''" @click="toChange(1)">分销</div>
                    <div class="item" :class="type==2?'act':''" @click="toChange(2)">店铺装修</div>
                    <div class="item" :class="type==3?'act':''" @click="toChange(3)">多商户</div>
                    <div class="item" :class="type==4?'act':''" @click="toChange(4)">O2O</div>
                    <div class="item" :class="type==5?'act':''" @click="toChange(5)">收银台</div>
                    <div class="item" :class="type==6?'act':''" @click="toChange(6)">小程序</div>
                    <div class="item" :class="type==7?'act':''" @click="toChange(7)">互动直播</div>
                    <div class="item" :class="type==8?'act':''" @click="toChange(8)">手机端商家管理中心</div>
                </div>
            </div>
            <div class="box">               
                <div class="cont" v-if="type==1">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/fx-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">传播快</div>
                        <div class="ntxt">微信活跃用户突破9亿，通过朋友圈可迅速传播商城信息</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/fx-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">易推广</div>
                        <div class="ntxt">人人都是商城的义务宣传员，实现裂变式推广营销</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/fx-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">易管理</div>
                        <div class="ntxt">您可随时在商城后台管理分销商情况，设备基本信息</div>
                  </div>
                </div>
            </div>
            <div class="cont" v-if="type==2">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dpzx-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">零经验</div>
                        <div class="ntxt">30秒即可迅速掌握装修技巧，零经验开店不求人</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dpzx-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">个性化</div>
                        <div class="ntxt">店铺装修是一款针对互联网电商的DIY装修工具，个性商城轻松拥有</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dpzx-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">多模版</div>
                        <div class="ntxt">系统为您提供20余款装修模版您可一键装修，快速开店。</div>
                  </div>
                </div>
            </div>

            <div class="cont" v-if="type==3">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dsh-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">一键入驻</div>
                        <div class="ntxt">平台通过您的入驻申请，即可入住商城，无需冗杂申请条件，即刻开启您的电商之旅</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dsh-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">独立页面</div>
                        <div class="ntxt">您可拥有独立的多商户商品展示页面，支持店铺装修功能，多种布局排列展示风格，有助于店铺商品宣传，促进消费</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dsh-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">无需经验</div>
                        <div class="ntxt">即使您没有店铺营销管理方面的经验，也可依托商城强大的客户流量资源，打造人气店铺</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/dsh-4@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">统一管理</div>
                        <div class="ntxt">商城平台实行统一化、规范化的交易管理体系，保证了您的根本利益，有利于多商户平台的健康发展</div>
                  </div>
                </div>
            </div>

            <div class="cont" v-if="type==4">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/020-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">全方位资源整合</div>
                        <div class="ntxt">平台通过您的入驻申请，即可入住商城，无需冗杂申请条件，即刻开启您的电商之旅</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/020-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">互动式服务</div>
                        <div class="ntxt">融合互联网线上经营管理核心，与传统行业无缝对接，打造一体化经营平台</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/020-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">全新商业模式</div>
                        <div class="ntxt">引领潮流的全新商业模式，网络和实体店铺全方位、多渠道经营，实现销售业绩爆发式增长</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/020-4@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">多场景应用</div>
                        <div class="ntxt">紧随时代发展趋势，满足不同场景服务需求，助力电子商务平台打造多场景服务管理体系</div>
                  </div>
                </div>
            </div>

            <div class="cont" v-if="type==5">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/syt-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">方便安全</div>
                        <div class="ntxt">扫码即付，简化支付流程，避免交易出现假钞，账目流水便于及时查看</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/syt-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">多种支付</div>
                        <div class="ntxt">会员可扫商户付款二维码支付、收银员可扫会员付款二维码支付、收银员用扫码枪扫会员付款二维码收款</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/syt-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">独立后台</div>
                        <div class="ntxt">独立收款后台，类目信息显示简洁明了，便于操作使用，针对线下的收银系统</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/syt-4@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">同步打印小票</div>
                        <div class="ntxt">开启收银系统小票打印功能，自动同步对接店铺订单信息避免高峰漏单问题，方便账目统计</div>
                  </div>
                </div>
            </div>

            <div class="cont" v-if="type==6">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/xcx-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">支持分销系统</div>
                        <div class="ntxt">商城小程序完全支持商城分销系统</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/xcx-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">兼容商品数据</div>
                        <div class="ntxt">小程序兼容商城商品分类数据，无需重新上传分类</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/xcx-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">支持首页排版</div>
                        <div class="ntxt">人人商城小程序首页支持排版调整，可个性化</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/xcx-4@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">可同步会员信息</div>
                        <div class="ntxt">可通过手机号绑定，同步商城会员信息，无需重新填写</div>
                  </div>
                </div>
            </div>

            <div class="cont" v-if="type==7">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/hdzb-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">即时互动</div>
                        <div class="ntxt">实时与直播用户进行沟通交流、推送促销信息</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/hdzb-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">多种促销</div>
                        <div class="ntxt">实时推送商品、红包、优惠券多种促销</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/hdzb-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">独立控制台</div>
                        <div class="ntxt">每个直播间拥有独立的控制台，便于消息、图文、推送管理</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/hdzb-4@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">融合商城功能</div>
                        <div class="ntxt">直播与商城购物完美结合，以视频方式吸引线上关注</div>
                  </div>
                </div>
            </div>

            <div class="cont" v-if="type==8">                  
                  <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/sj-1@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">支持商城会员管理</div>
                        <div class="ntxt">您可查看信息详情、为会员充值商城余额或积分、查看会员订单信息，一部手机即可管理会员信息</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/sj-2@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">支持财务管理</div>
                        <div class="ntxt">手机端商城管家可查看会员的充值记录、提现记录、积分或余额明细</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/sj-3@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">支持商品管理</div>
                        <div class="ntxt">您可进行商品批量管理、可添加上架或下架商品、编辑或查看商品详情、删除商品</div>
                  </div>
                </div>
                <div class="card fadeInUp">
                    <div class="timg"><img src="../assets/ximg/sj-4@2x.png"/></div>
                    <div class="btoom">
                        <div class="btxt">支持订单管理</div>
                        <div class="ntxt">您可随时查看待发货、待付款、待收货、已完成、已关闭订单，轻松管理商城订单业务</div>
                  </div>
                </div>
            </div>

            </div>
		</div>

		<div class="save_box4">
			<h1 class="box4_title">定制服务流程</h1>
			<h2 class="box4_tip">按需定制，快速响应客户定制需求</h2>
			<div class="box4_bot">
				<div class="line">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求调研</div>
						<div class="tip2">01</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">需求分析</div>
						<div class="tip2">02</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">原型设计</div>
						<div class="tip2">03</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">产品设计</div>
						<div class="tip2">04</div>
					</div>
				</div>
				<div class="bank">
					<div class="zzimg">
						<img src="../assets/ximg/jt.png" alt="" />
					</div>
				</div>
				<div class="line resco">
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqdy.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">界面设计</div>
						<div class="tip2">05</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-xqfx.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">程序编码</div>
						<div class="tip2">06</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-yxsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">软件测试</div>
						<div class="tip2">07</div>
					</div>
					<img src="../assets/ximg/jt.png" alt="" class="jtico" />
					<div class="box_item">
						<img
							src="../assets/ximg/icon-cpsj.png"
							alt=""
							class="img1"
						/>
						<div class="tip1">打包发布</div>
						<div class="tip2">08</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="btn" @click="toPage('/customizing')">查看演示版</div>
			</div>
		</div>

		<mbottom />
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/swiper.css";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			// this.$router.replace({ path: "X3matrix" });
			return {
				current: 0,
                type:1,
				autoplay: true,
				index: 0,
				banbox: [],
				problem: [],
				umobile: "",
				buy: true,
				buynum: 1,
				show: false,
				shows: false,
				shloading: false,
				sqloading: false,
				list: [],
			};
		},
		focus: {
			inserted: function (el, { value }) {
				console.log(el, { value });
				if (value) {
					el.focus();
				}
			},
		},

		components: {
			mtop,
			mbottom,
		},

		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
			this.initCase();
		},
		watch: {
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			this.getinfo();
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
            toChange(e){
                this.type=e
            },
			outleft() {
				this.autoplay = true;
			},
			moveleft(val) {
				console.log(val);
				this.autoplay = false;
				this.index = val;
			},
			changeban(val) {
				console.log(val);
				this.index = val;
			},
			kefu() {
				//   console.log(1111);
				//    window._MICHAT = window._MICHAT || function () { (_MICHAT.a = _MICHAT.a || []).push(arguments) };
				// _MICHAT("accountid", 127020);
				// _MICHAT("domain", "mamkf.kanuomei.com");
				// (function (m, d, q, j, s) {
				//     j = d.createElement(q),s = d.getElementsByTagName(q)[0];
				//     j.async = true;
				//     j.charset ="UTF-8";
				//     j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "mamkf.kanuomei.com/Web/JS/mivisit.js?_=t";
				//     s.parentNode.insertBefore(j, s);
				// })(window, document, "script");
			},
			toPage(row) {
				this.$router.push(row);
			},
			getprice() {
				if (this.umobile == "") {
					this.$toast("请填写正确手机号!");
					return false;
				} else {
					this.$axios({
						method: "post",
						url: "/v1/apply",
						params: { mobile: this.umobile },
					})
						.then((res) => {
							console.log(res);
							if (res.data.code == 200) {
								this.$toast("成功提交");
								this.umobile = "";
							} else {
								this.$toast(res.data.msg);
							}
						})
						.catch((resp) => {
							this.$toast(resp.data.msg);
						});
				}
			},

			getinfo() {
				//   this.$axios({
				//     method:'get',
				//     url:'/v1/articleList',
				//     params:{type:2,cate_id:1,is_hot:1},
				// }).then((res)=>{
				//     this.list=res.data.data.list.data;
				// }).catch(resp => {
				//     console.log('请求失败');
				// });
			},
			to(e) {
				// this.$router.replace({ path: e });
				location.href = e;
			},
			tobuy() {
				location.href =
					"https://opensea.io/collection/cupid-love-metaverse";
			},

			close() {
				this.shows = false;
			},
			initCase() {
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#case", {
					loop: false,
					breakpoints: {
						320: {
							//当屏幕宽度大于等于320
							slidesPerView: 4,
						},
						750: {
							//当屏幕宽度大于等于768
							slidesPerView: 5,
						},
					},
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	$t-mf: "Arial";
	.case-tab {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #dcdee0;
		.case-nav {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #646566;
			padding: 10px 0;
			cursor: pointer;
			&.active {
				font-size: 16px;
				font-weight: 500;
				color: #2b6bff;
				border-bottom: 1px solid #2b6bff;
			}
		}
	}
	.main {
		background: #fff;
		max-width: 100%;
		min-width: 1200px;
		width: 100%;
        .htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }
        .content{
            .ctitle{
                font-size: 32px;
font-weight: 500;
color: #1D2129;
line-height: 45px;
text-align: center;
margin: 100px auto 60px;
            }
            .ctop{                           
		border-bottom: 1px solid #dcdee0;
        width: 100%;
        .tab{
            display: flex;
		justify-content: space-between;
        width: 1200px;
        margin: 0 auto;        
		.item {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #646566;
			padding: 10px 0;
			cursor: pointer;
			&.act {
				font-size: 16px;
				font-weight: 500;
				color: #2b6bff;
				border-bottom: 2px solid #2b6bff;
			}
		}
    }   
                        }
            .box{
                width: 1200px;
					margin: 0 auto;
					padding: 86px 0;
                    .btop{
						display: flex;
						align-items: center;
						flex-direction: column;
						margin: 0px auto 62px;
						.title{
							font-size: 32px;
							font-weight: 500;
							color: #1D2129;
							line-height: 45px;
							margin-bottom: 16px;
						}
						.tips{
							font-size: 16px;
							font-weight: 400;
							color: #646566;
							line-height: 22px;
						}
					}
                    .cont{
                        display: flex;
                        align-items:center;
                         .right,.left{
                            width: 666px;
                            height: 447px;                           
                            img{
                                width: 100%;
                                height: 100%;
                            }
                            }
                            .left{
                                margin-right: 63px;
                            }
                            .right{
                                margin-left: 63px;
                            }
                        .bxcard{
                            // min-height: 583px;
                            width: 487px;
                            .title{
                                font-size: 32px;
                                font-weight: 500;
                                color: #000000;
                                line-height: 45px;
                                margin-bottom: 34px;
                            }
                            .line{
                                display: flex;
                                align-items: flex-start;
                                margin: 24px 0;
                                .ico{
                                    width: 21px;
                                    height: 21px;
                                    margin-right: 25px;
                                    img{
                                        width: 21px;
                                    height: 21px;
                                    }
                                }
                                .xbox{
                                    .stxt{
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #000000;
                                        line-height: 20px;
                                        .bb{
                                            font-size: 24px;
                                        }
                                    }
                                    .xtips{
                                        font-size: 14px;
                                        margin-top: 5px;
                                        font-weight: 400;
                                        color: #646566;
                                        line-height: 20px; 
                                    }
                                }
                            }
                        }

                        .vcard{
                            width: 384px;
                            height: 360px;
                            background: #F1F5FF;
                            border-radius: 4px;
                            padding: 34px;
                            box-sizing: border-box;
                            position: relative;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            flex-direction: column;
                            margin: 68px 12px;
                            .timg{
                                   
                                   height: 160px;
                                   width: 254px;
                                   margin: 0 auto;
                                   img{
                                    height: 254px;
                                   width: 254px;
                                    position: absolute; 
                                   top: -65px;
                                   left: 50%;
                                //    border: 1px solid #000000;
                                   transform: translateX(-50%);
                                   }

                            }
                            .bottom{
                                .title{
                                    font-size: 18px;
                                    font-weight: 500;
                                    color: #000000;
                                    line-height: 25px;
                                    text-align: center;
                                    margin-bottom: 16px;
                                }
                                .txt{
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #515666;
                                    line-height: 20px;
                                }
                            }
                        }
                        .card{
                            width: 282px;
height: 387px;
background: linear-gradient(180deg, #F3F7FD 0%, #FFFFFF 100%);
box-shadow: 0px 0px 20px 0px rgba(16,38,80,0.1);
border-radius: 2px;
border: 1px solid #EBEDF0;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center; 
padding: 24px;
box-sizing: border-box;
margin: 0 12px;
.timg{
    width: 90px;
    height: 90px;
    img{
        width: 100%;
        height: 100%;
    }
}  
    .btoom{
        margin-top:59px ;
        .btxt{font-size: 18px;
font-weight: 500;
color: #1D2129;
text-align: center;
margin-bottom: 16px;
line-height: 25px;}
.ntxt{
    font-size: 14px;
font-weight: 400;
color: #646566;
text-align: left;
line-height: 20px;
}
    }

                        }
                       
                    }

                    .lint{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        .card{
                            width: 292px;
                            height: 224px;
                            position: relative;
                            z-index: 1;
                            padding: 24px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            .ibg{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                z-index: -1;
                            }
                            .header{
                                font-size: 24px;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 33px; 
                                border-bottom: 1px solid #ffffff;
                                padding-bottom: 16px;
                            }
                            .cbox{
                                margin-top: 16px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 21px;
                            }
                        }
                        .flex{
                            flex: 1;
                            margin: 0 10px;
                        }
                    }
            }
        }
        .bg{
            background: url(~@/assets/ximg/background-fxsc@2x.png) no-repeat;
			background-size: cover;
			background-position: center center;
        }
		.save_box1 {
			cursor: default;
			width: 100%;
			padding: 90px 0;
			background: #f4f6fe;
			display: flex;
			height: 702px;
			box-sizing: border-box;
			flex-direction: column;
			text-align: center;
			.box1_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0px;
			}
			.box1_tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin: 0px;
				line-height: 50px;
			}
			.box1_bottom {
				display: flex;
				justify-content: space-between;
				width: 1200px;
				margin: 36px auto;
				.card {
					width: 363px;
					padding: 50px 0;
					height: 216px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					flex-direction: column;
					align-items: center;
					overflow: hidden;
					.img {
						img {
							width: 90px;
							height: 90px;
						}
						margin-bottom: 35px;
					}
					.title {
						font-size: 24px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #1d2129;
						line-height: 33px;
						margin-bottom: 16px;
					}
					.conttxt {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #969799;
					}
					.btn {
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #2b6bff;
						line-height: 40px;
						width: 112px;
						height: 40px;
						background: #ffffff;
						border-radius: 2px;
						border: 1px solid #2b6bff;
						margin-top: 50px;
						display: none;
					}
				}
				.card:hover {
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					height: auto;
					transition: all 0.8s ease-in;
					.btn {
						display: block;
						cursor: pointer;
					}
				}
			}
		}
		.save_box2 {
			cursor: default;
			// width:1920px;
			min-height: 626px;
			background: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 90px 0;
			width: 1200px;
			margin: 0 auto;
			.box2_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin: 0 auto;
				width: 100%;
				text-align: center;
			}
			.box2_tip {
				width: 100%;
				margin: 0 auto;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				text-align: center;
				line-height: 50px;
			}
			.box2_bot {
				display: flex;
				width: 1200px;
				margin: 20px auto;
				justify-content: space-between;
				flex-direction: column;
				.bot_item {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #ffffff;
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
					border-radius: 2px;
					margin: 22px 0;
					padding: 12px 73px;
					box-sizing: border-box;
					.left {
						img {
							width: 433px;
							height: auto;
						}
					}
					.right {
						width: 476px;
						.title {
							font-size: 24px;
							font-weight: 500;
							color: #1d2129;
							line-height: 33px;
							text-align: left;
							margin: 30px 0;
							position: relative;
							&::after {
								content: "";
								width: 22px;
								height: 4px;
								background: #2b6bff;
								border-radius: 2px;
								position: absolute;
								left: 0;
								bottom: -15px;
							}
						}
						.sinfo {
							font-size: 14px;
							font-weight: 400;
							color: #969799;
							text-align: left;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: row-reverse;
				}

				.item2 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fee2cd 0%,
							#fff2e7 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #8b3b34;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #8b3b34;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #8b3b34;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							line-height: 20px;
						}
					}
				}

				.item3 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							92deg,
							#eff8ff 0%,
							#d0e7f9 100%
						);
						box-sizing: border-box;
						padding: 20px;

						div {
							color: #0c63d7;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;

							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								color: #0c63d7;
								font-size: 18px;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ffffff;
						border: 1px solid #0c63d7;
						color: #0c63d7;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #0c63d7;
						span {
							font-size: 10px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #0c63d7;
							line-height: 20px;
						}
					}
				}

				.item4 {
					.item_head {
						width: 100%;
						height: 87px;
						text-align: center;
						background: linear-gradient(
							264deg,
							#fec0ae 0%,
							#ffe0d7 100%
						);
						box-sizing: border-box;
						padding: 20px;
						color: #ff8460;
						div {
							color: #ff8460;
						}
						div:nth-child(1) {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 30px;
						}
						div:nth-child(2) {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #8b3b34;
							line-height: 20px;
						}
					}
					.item_con {
						box-sizing: border-box;
						padding: 20px;
						.con_item {
							display: flex;
							justify-content: space-between;
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							line-height: 30px;
							span {
								font-size: 18px;
								color: #ff8460;
							}
						}
					}
					.item_bot {
						width: 186px;
						height: 41px;
						background: #ff8460;
						border-radius: 4px;
						text-align: center;
						margin: 0 auto;
						line-height: 41px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						a {
							color: #ffff;
							span {
								font-size: 10px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
								line-height: 20px;
							}
						}
					}
				}
			}
		}
		.save_box3 {
			cursor: default;
			width: 100%;
			height: 578px;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.box3_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				width: 1200px;
				margin: 0 auto;
			}
			.box3_con {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				width: 1200px;
				margin: 0 auto;
				line-height: 50px;
			}
			.box3_bot {
				width: 1200px;
				margin: 40px auto;
				padding: 0 30px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				.bot_item {
					width: 234px;
					height: 300px;
					background: #ffffff;
					box-shadow: 0px 0px 21px 0px rgba(81, 81, 81, 0.19);
					border-radius: 4px;
					border-bottom: 3px solid #0fb3b4;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					box-sizing: border-box;
					padding: 0 30px;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
					div:nth-child(2) {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 30px;
						margin-top: 40px;
						text-align: center;
					}
					div:nth-child(3) {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7e8184;
						line-height: 30px;
						text-align: left;
						margin-top: 27px;
						text-align: center;
					}
				}
				.you1 {
					border-bottom: 3px solid #0fb3b4;
					div:nth-child(1) {
						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #0fb3b4;
						line-height: 30px;
					}
				}
				.you2 {
					border-bottom: 3px solid #8b3b34;
					div:nth-child(1) {
						color: #8b3b34;
					}
				}
				.you3 {
					border-bottom: 3px solid #16d1f1;
					div:nth-child(1) {
						color: #16d1f1;
					}
				}
				.you4 {
					border-bottom: 3px solid #ff8460;
					div:nth-child(1) {
						color: #ff8460;
					}
				}
				.bot_item:hover {
					color: #fff !important;
					background: linear-gradient(0deg, #1a8eff 0%, #19d5f5 100%);
					box-shadow: 0px 0px 21px 0px rgba(26, 150, 254, 0.19);
					border-radius: 10px;
					transform: scale(1.1);
					div {
						color: #fff !important;
					}
				}
			}
		}
		.save_box4 {
			height: 589px;
			background: #f4f6fe;
			padding: 1px;
			width: 100%;
			margin: 0 auto;
			.box4_title {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				margin-top: 54px;
			}
			.box4_tip {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin-top: 15px;
			}
			.box4_bot {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 1200px;
				margin: 0 auto;
				margin-top: 25px;
				.line {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.jtico {
						width: 45px;
						height: 45px;
						margin: 0 11px;
					}
				}
				.resco {
					flex-direction: row-reverse;
					.jtico {
						transform: rotate(180deg);
					}
				}
				.bank {
					display: flex;
					justify-content: flex-end;
					width: 100%;
					.zzimg {
						width: 249px;
						height: 75px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					img {
						width: 45px;
						height: 45px;
						transform: rotate(90deg);
					}
				}
				.box_item {
					width: 249px;
					height: 75px;
					background: #ffffff;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					position: relative;
					.img1 {
						width: 25px;
						height: 25px;
					}
					.tip1 {
						font-size: 18px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #1d2129;
						line-height: 27px;
						margin-left: 15px;
					}
					.tip2 {
						position: absolute;
						bottom: 0;
						right: 0;
						font-size: 50px;
						font-family: DINPro-Bold, DINPro;
						font-weight: bold;
						color: #f7f8fa;
						line-height: 65px;
					}
				}

				.box_item:hover {
					transform: scale(1.02);
					box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
				}
			}
			.bottom {
				width: 100%;
				display: flex;
				justify-content: center;
				margin: 59px 0;
				text-align: center;
				.btn {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #2B6BFF;
					line-height: 40px;
					width: 128px;
                    height: 40px;
                    border-radius: 2px;
                    border: 1px solid #2B6BFF;
					cursor: pointer;
				}
			}
		}
		.save_box5 {
			// max-height:520px;
			min-height: 300px;
			background: #fff;
			.box5_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 55px;
			}
			.box5-tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #020202;
				width: 1200px;
				margin: 0 auto;
				text-align: center;
				margin-top: 13px;
			}
		}
		.box5_look {
			width: 100px;
			height: 34px;
			background: #ffffff;
			border: 1px solid #e9e9e9;
			border-radius: 17px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			line-height: 34px;
			margin: 0 auto;
			text-align: center;
			margin-bottom: 35px;
			// margin-top:-38px;
		}
		.box5_look:hover {
			background: linear-gradient(0deg, #1a8eff 0%, #16d1f1 100%);
			color: #fff;
		}
		.box5_bot {
			width: 1200px;
			margin: 0 auto;
			margin-top: 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.box5_item {
				width: 590px;
				height: 95px;
				background: #ffffff;
				border: 1px solid #e9e9e9;
				margin-bottom: 20px;
				box-sizing: border-box;
				padding: 15px;
				text-align: left;
				div:nth-child(1) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
				}
				div:nth-child(2) {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					line-height: 16px;
					margin-top: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
			.box5_item:hover {
				background: linear-gradient(0deg, #eee 0%, #fff 100%);
			}
		}
	}
	.h5 {
		width: 100%;
		min-width: 100%;
        .htop{
            height:125px;
        }
        .banner{
    width: 100%;    
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
  .content{
    .box{
        width: 100%;

        .cont{
            flex-direction: column;
            .card{
                width: 80%;
                margin: 24px;
                .btoom{
                    .btxt{
                    font-size: 32px;
                    }
                    .ntxt{
                        font-size: 24px;
                        line-height: 38px;
                    }  
                }
            }
            .bxcard{
                width: 80%;
                .title{
                    font-size: 36px;
                }
                .line{
                    .ico{
                        
                        width: 40px;
                        height: 40px;
                        img{
                            width: 40px;
                        height: 40px;
                        }

                    }
                .xbox{
                    .stxt{
                        font-size: 28px;
                        line-height: 36px;
                        .bb{
                            font-size: 32px;
                        }
                    }
                    .xtips{
                        font-size: 24px;
                        line-height: 38px;
                    }
                }
            }
            }
           .left{
                margin:24px 0;
            }
            .right{
                margin:24px 0;
            }
            .vcard{
                width: 80%;
                min-height: 333px;
                .bottom{
                    .title{
                    font-size: 32px;
                    }
                    .txt{
                        font-size: 24px;
                        line-height: 38px;
                    }
                }
            }
        }
    }
  }
		.save_box1 {
			width: 100%;
			height: auto;
			text-align: center;
			.box1_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box1_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box1_bottom {
				flex-direction: column;
				width: 100%;
				.card {
					width: calc(100% - 100px);
					height: auto;
					margin: 20px auto;
					.img {
						img {
							width: 200px;
							height: 200px;
						}
					}
					.title {
						font-size: 32px;
					}
					.conttxt {
						line-height: 36px;
						font-size: 26px;
					}
					.btn {
						width: 200px;
						height: 68px;
						font-size: 30px;
						line-height: 68px;
					}
				}
			}
		}
		.save_box2 {
			width: 100%;
			.box2_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box2_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box2_bot {
				width: 100%;
				.bot_item {
					flex-direction: column;
					padding-bottom: 40px;
					.left {
						img {
							width: calc(100% - 60px);
							height: 100%;
							margin: 0 30px;
						}
					}
					.right {
						.title {
							font-size: 32px;
						}
						.sinfo {
							font-size: 26px;
						}
					}
				}
				.bot_item:nth-child(2n) {
					flex-direction: column;
				}
			}
		}
		.save_box4 {
			width: 100%;
			height: 100%;
			.box4_title {
				font-size: 36px;
				font-weight: 600;
			}
			.box4_tip {
				font-size: 26px;
				margin: 20px auto;
			}
			.box4_bot {
				width: 100%;
				margin: 60px auto;
				.line {
					flex-direction: column;
					width: 80%;
					margin: 0 auto;
					.box_item {
						width: 100%;
						flex-direction: column;
						height: 100%;
						padding: 20px;
						border-radius: 10px;
						.tip1 {
							margin-left: 0;
							font-size: 26px;
						}
						.img1 {
							width: 66px;
							height: 66px;
							margin: 20px 0;
						}
					}
					.jtico {
						transform: rotate(90deg);
						margin: 20px;
						height: 100px;
						width: 100px;
					}
				}
				.bank {
					justify-content: center;
					.zzimg {
						height: 100px;
						margin: 20px;
						img {
							height: 100px;
							width: 100px;
						}
					}
				}
			}
			.bottom {
				.btn {
					width: 300px;
					height: 100px;
					line-height: 100px;
					font-size: 30px;
					border-radius: 10px;
				}
			}
		}
	}
</style>
